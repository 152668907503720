import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { log } from 'utils/logger';

export default function useSignallingHook() {
  const signalRef = useSelector(state => state?.interviewRoomsRedirect?.signalRefData);
  const participantRefData = useSelector(state => state?.interviewRoomsRedirect?.participantRefData);

  const sendSignalling = useCallback(
    (type, message, to, from, message_type, notification_type) => {
      (async () => {
        await addDoc(signalRef, {
          created_at: serverTimestamp(),
          sent_at: serverTimestamp(),
          created_by: from || null,
          is_deleted: false,
          reciever: to || null,
          sender: from || null,
          signal_message: message || null,
          signal_type: type || null,
          message_type: message_type || 'default',
          acknowledged: [],
          notification_type: notification_type || 'info',
        }).catch(error => log('Firebase Error: ', error));
      })(type, message, to, from);
    },
    [signalRef],
  );

  const updateSignalling = useCallback((signalRefData, id, participantId) => {
    const newRef = doc(signalRefData, id);
    updateDoc(newRef, {
      acknowledged: arrayUnion(Number(participantId)),
    }).catch(error => log('Firebase Error: ', error));
  }, []);

  const addDocToFirestore = useCallback(
    ({
      meetingStatus,
      meetingParticipantId,
      participantEmail,
      participantName,
      participantRole,
      videoServiceProviders,
      recordingStatus,
      currentTab,
    }) => {
      (async () => {
        await addDoc(participantRefData, {
          created_at: serverTimestamp(),
          id: meetingParticipantId || '',
          email: participantEmail || '',
          name: participantName || '',
          meeting_participant_id: meetingParticipantId || '',
          in_meeting: meetingStatus || '',
          role: participantRole || '',
          fallback_video_service_providers: videoServiceProviders || '',
          recording_status: typeof recordingStatus === 'boolean' ? recordingStatus : null,
          current_tab: currentTab || null,
        }).catch(error => log('Firebase Error: ', error));
      })();
    },
    [participantRefData],
  );

  return [sendSignalling, updateSignalling, addDocToFirestore];
}
