import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';

const growthbook = new GrowthBook({
  apiHost: process.env.GB_API_HOST || 'https://gb-proxy.talview.com',
  clientKey: process.env.GB_CLIENT_KEY || 'sdk-rVvxcOXY6UomO17g',
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback(experiment, result) {
    Mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});
export default growthbook;
