import { Room, createLocalVideoTrack, createLocalTracks } from 'livekit-client';
import Video from 'twilio-video';
import { useSelector } from 'react-redux';
import { videoServiceProviderState } from 'containers/IRRedirect/selectors';

const roomMap = {
  TWILIO: Video,
  LIVEKIT: Room,
  LIVEKIT_SELF_HOSTED: Room,
};

const createlocalTrackMap = {
  TWILIO: Video.createLocalTracks,
  LIVEKIT: createLocalTracks,
  LIVEKIT_SELF_HOSTED: createLocalTracks,
};

const createlocalVideoTrackMap = {
  TWILIO: Video.createLocalVideoTrack,
  LIVEKIT: createLocalVideoTrack,
  LIVEKIT_SELF_HOSTED: createLocalVideoTrack,
};

export default function VideoServiceInterface() {
  const irVideoServiceProvider = useSelector(videoServiceProviderState);

  const RoomObj = roomMap[irVideoServiceProvider] || Room;
  const createLocalTracksFn = createlocalTrackMap[irVideoServiceProvider] || createLocalTracks;
  const createLocalVideoTrackFn = createlocalVideoTrackMap[irVideoServiceProvider] || createLocalVideoTrack;

  return {
    RoomObj,
    createLocalTracksFn,
    createLocalVideoTrackFn,
    irVideoServiceProvider,
  };
}
